<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="
          $router.push({
            name: 'EssAgrreements',
            query: { activeTab: 'My Direct Reports' },
          })
        "
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 220px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Draft Agreement
        </h1>
      </div>

      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <div class="px-3">
        <card class="p-5 mt-3">
          <p class="text-lg font-bold">Agreement Details</p>
          <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
            <div class="col-span-4">
              <div class="text-sm text-jet">Financial Year</div>
              <div
                class="date-flex mt-1"
                :style="periodError ? 'border : 1px solid red' : ''"
              >
                <datepicker
                  placeholder="--Select Day & Month & Year--"
                  style="width: 100%; outline: none"
                  input-class="date-input"
                  format="yyyy"
                  minimum-view="year"
                  @selected="periodSelect"
                  v-model="payload.year"
                />
              </div>
              <small
                data-v-d5a084b0=""
                class="mt-1 text-red-700 text-xs font-semibold"
                v-if="periodError"
                >Financial year is not valid.</small
              >
            </div>

            <div class="col-span-4">
              <div class="text-sm text-jet">Description</div>
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                class="label mt-2"
                v-model="payload.title"
                label="Description"
                :rules="['required']"
                :disabled="true"
              />
            </div>

            <div class="col-span-4">
              <div class="text-sm text-jet">Template</div>
              <c-select
                label="Template"
                placeholder="Select Template"
                variant="w-full"
                class="label mt-2"
                :options="templateOptions"
                v-model="selectedTemplate"
                :rules="['required']"
              />
            </div>
          </div>

          <div
            class="w-full p-2 h-auto flex flex-col mt-5 cycle"
            v-if="selectedTemplate !== ''"
          >
            <div class="w-11/12 flex cycle__text">CYCLE DETAILS</div>

            <card class="w-full fcard">
              <div class="w-full flex h-auto">
                <div class="fcard__icon">
                  <icon :icon-name="doccycle" size="csm" />
                </div>
                <div class="w-full flex flex-col h-auto">
                  <div class="fcard__header" style="color: #490166">
                    {{ cycleTitle }}
                  </div>
                  <div class="w-full fcard__text">
                    {{ cycleDescription }}
                  </div>
                </div>
              </div>
            </card>

            <div class="w-full h-auto flex flex-wrap">
              <card class="w-5/12 flex flex-grow mr-3 fcard">
                <div class="w-full flex h-auto">
                  <div class="fcard__icon">
                    <icon :icon-name="calendarIcon" size="csm" />
                  </div>
                  <div class="w-full flex flex-col h-auto">
                    <div class="fcard__header" style="color: #e99323">
                      Cycle Period
                    </div>
                    <div class="w-full fcard__text">
                      {{ $DATEFORMAT(new Date(cycleStartPeriod), "MMMM dd") }}
                      -
                      {{ $DATEFORMAT(new Date(cycleEndPeriod), "MMMM dd") }}
                    </div>
                  </div>
                </div>
              </card>

              <card
                class="w-5/12 flex flex-grow mb-3 fcard"
                v-for="(cycles, index) in appraisal_cycles"
                :key="index"
              >
                <div class="w-full flex h-auto">
                  <div class="fcard__icon">
                    <icon :icon-name="reviewIcon" size="csm" />
                  </div>
                  <div class="w-full flex flex-col h-auto">
                    <div class="fcard__header" style="color: #2176ff">
                      {{ cycles.name }}
                    </div>
                    <div class="w-full fcard__text">
                      {{
                        $DATEFORMAT(
                          new Date(cycles.appraisal_starts),
                          "MMMM dd"
                        )
                      }}
                      -
                      {{
                        $DATEFORMAT(new Date(cycles.appraisal_ends), "MMMM dd")
                      }}
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>

          <div
            class="w-full p-2 h-auto flex flex-col mt-5"
            style="border: 1px dashed #878e99; border-radius: 5px"
            v-else
          >
            <div class="w-11/12 h-6 p-1 flex cyc-text">PERFORMANCE DETAILS</div>
            <div class="w-11/12 h-6 p-1 mt-4 mb-3 inner-text">
              --Select a Performance Template to view Appraisal Performance
              Details here---
            </div>
          </div>

          <div
            class="w-full p-2 h-auto flex flex-col mt-5"
            style="border: 1px dashed #878e99; border-radius: 5px"
          >
            <div class="w-full flex">
              <div class="w-auto h-6 p-1 flex flex-grow cyc-text uppercase">
                Create For
              </div>
              <div
                class="text-flame font-semibold flex mb-1 p-2 text-base cursor-pointer"
                @click="addEmployees()"
              >
                <icon icon-name="icon-plus" class="mr-2" size="xs" />
                Employees
              </div>
            </div>
            <div class="w-full p-1 mt-4 mb-3 inner-text">
              <div class="w-full flex flex-wrap">
                <div
                  class="mb-3 w-1/3 flex border p-3 mr-2"
                  style="border-radius: 5px; width: 32.6%"
                  v-for="(item, index) in selectedEmployees"
                  :key="index"
                >
                  <div class="w-full flex">
                    <div class="flex flex-grow">
                      <div class="flex">
                        <img
                          class="mr-3"
                          :src="item.photo"
                          v-if="item.photo"
                          alt="user photo"
                          style="height: 30px; width: 30px; border-radius: 5px"
                        />
                        <div
                          style="height: 30px; width: 30px; border-radius: 5px"
                          class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold"
                          v-else
                        >
                          {{ $getInitials(`${item.fname} ${item.lname}`) }}
                        </div>
                      </div>
                      <div class="flex flex-col flex-grow">
                        <p class="flex -mt-1">
                          {{ `${item.fname} ${item.lname}` }}
                        </p>
                        <div
                          class="text-xs flex -mt-1 font-semibold w- text-romanSilver uppercase"
                        >
                          <p class="opacity-50 mr-2" style="font-size: 10px">
                            {{
                              `${
                                item.userDesignation
                                  ? item.userDesignation
                                  : "---"
                              }`
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="flex">
                      <span
                        class="flex cursor-pointer"
                        @click="viewEmployeeDetails(item.id)"
                      >
                        <Badge
                          label="Details"
                          variant="primary"
                          background-color="rgba(33, 118, 255, 0.08)"
                          color="#2176FF"
                          class="capitalize"
                          style="margin-right : 12px; font-size: 14px;
                      padding: 5px; height: 25px; margin-top: 0.5rem;
                      border-radius: 5px"
                        />
                      </span>
                      <span
                        class="flex cursor-pointer"
                        @click="removeEmployee(index, item)"
                      >
                        <icon
                          icon-name="close"
                          class-name="text-darkPurple cursor-pointer"
                          size="xs"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>

      <div class="px-3">
        <card class="p-5" style="margin-top: 4px">
          <div
            class="w-full flex flex-row"
            style="margin-top: 20px; height: 44px; flex-grow: 1"
          >
            <div class="w-9/12 h-auto mt-2">
              <h1 class="text-base font-bold">KPAs</h1>
            </div>

            <div
              class="h-full w-auto flex flex-row"
              v-if="selectedTemplate !== ''"
            >
              <div
                class="p-2 mx-3 bg-ghostWhite h-full flex"
                style="border-radius: 5px"
              >
                <span class="text-sm font-black flex flex-grow">
                  Total Allocated Weight:
                </span>
                <span class="text-sm text-desire ml-3">
                  {{ allocatedWeight }}%
                </span>
                <icon :icon-name="iconInfo" size="ksm" />
              </div>
            </div>
          </div>

          <div style="border-bottom: 1px solid #878e99; height: 1rem" />

          <div
            class="w-full h-auto my-6 flex flex-col"
            style="align-items: center"
            v-if="templateKPATypes.length === 0"
          >
            <div
              class="w-5/12 h-auto mb-4 mt-4 flex"
              style="justify-content: center"
            >
              <icon
                :icon-name="illustration"
                size="auto"
                class-name="icon-Size mb-2"
              />
            </div>
            <div class="w-6/12 h-auto illu-text">
              You set organizational goals and objectives here. KPAs appear here
              when you select a template. You can edit selected template based
              on permissions assigned. Select a template to get started.
            </div>
          </div>

          <div class="flex flex-col w-full my-4" v-else>
            <!----- List of KPA's ---->

            <div class="w-full h-auto flex flex-col">
              <accordion
                class="mt-6"
                v-for="(kpaTypes, kpaNumber) in templateKPATypes"
                :key="kpaNumber"
                show-divider
                @open="handleOpen($event, kpaTypes.id)"
                :is-open="true"
              >
                <template v-slot:title>
                  <span class="font-bold">{{ kpaTypes.name }}</span>
                </template>

                <template v-slot:block>
                  <div
                    class="p-2 w-auto flex gap-2 items-center bg-ghostWhite rounded-sm mr-2"
                  >
                    <span class="font-black text-xs">
                      {{ getKPAWeight(kpaTypes, kpaTypes.id) }}%
                    </span>
                  </div>
                </template>

                <div class="w-full">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <div class="w-full flex justify-end">
                      <div
                        class="text-flame font-semibold flex mt-3 mb-1 p-2 text-base cursor-pointer"
                        @click="handleSubmit(createNewKPA(kpaTypes))"
                      >
                        <icon icon-name="icon-plus" class="mr-2" size="xsm" />
                        Add New KPI
                      </div>
                    </div>

                    <scroll-container height="1000px">
                      <Table
                        :headers="KPAHeader"
                        :items="kpaTypes.kpas"
                        class="h-full my-2 table"
                        aria-label="absence table"
                        :has-number="false"
                      >
                        <template v-slot:item="{ item }">
                          <div v-if="item.state" class="-ml-3">
                            <div v-if="item.data.state" class="p-2">
                              <div class="flex flex-col">
                                <div
                                  class="cursor-pointer"
                                  v-if="item.data.isLocked"
                                  @click="
                                    lockKPA(item.data.isLocked, item.data)
                                  "
                                >
                                  <icon
                                    icon-name="icon-lock"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                                <div
                                  class="cursor-pointer"
                                  v-else
                                  @click="
                                    lockKPA(item.data.isLocked, item.data)
                                  "
                                >
                                  <icon
                                    icon-name="icon-unlock"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                                <div
                                  class="cursor-pointer"
                                  @click="deleteKPA(item.index, kpaTypes, item.data.kpaId)"
                                  v-if="!item.data.isLocked"
                                >
                                  <icon
                                    icon-name="icon-trash"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-else-if="item.name">
                            <div class="label">
                              <c-text
                                placeholder="--Enter KPI--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-1 w-72"
                                :disabled="item.data.isLocked"
                                v-model="item.data.name"
                                v-tooltip.bottom-middle="item.data.name"
                                :rules="['required']"
                                label="KPI"
                              />
                            </div>
                          </div>

                          <div v-else-if="item.objective">
                            <div class="label">
                              <c-text
                                placeholder="--Enter Objective--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-2 w-72"
                                :disabled="item.data.isLocked"
                                v-model="item.data.objective"
                                v-tooltip.bottom-middle="item.data.objective"
                                :rules="['required']"
                                label="Objective"
                              />
                            </div>
                          </div>

                          <div v-else-if="item.kpis" class="flex">
                            <div
                              class="label flex"
                              v-for="(n, rating) in item.data.kpis"
                              :key="rating"
                            >
                              <ValidationObserver>
                                <c-text
                                  variant="w-full"
                                  :class="`mr-2 -mt-2 w-48`"
                                  :placeholder="`Criteria ${rating}`"
                                  :disabled="item.data.isLocked"
                                  v-model="item.data.kpis[rating].criteria"
                                  v-tooltip.bottom-middle="
                                    item.data.kpis[rating].criteria
                                  "
                                  :rules="['required']"
                                  :label="`Criteria ${rating} value`"
                                />
                              </ValidationObserver>
                            </div>
                          </div>

                          <div v-else-if="item.target_date">
                            <div class="label w-72">
                              <div
                                class="date-flex -mt-2"
                                :class="item.data.isLocked ? 'bg-disabled' : ''"
                              >
                                <datepicker
                                  placeholder="--Select Day & Month & Year--"
                                  style="width: 100%; outline: none"
                                  input-class="date-input"
                                  format="yyyy-MMMM-dd"
                                  v-model="item.data.target_date"
                                  v-tooltip.bottom-middle="
                                    item.data.target_date
                                  "
                                  :disabled="item.data.isLocked"
                                />
                              </div>
                            </div>
                          </div>

                          <div v-else-if="item.weight">
                            <div class="label">
                              <c-text
                                placeholder="--Enter Weight--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-2 w-24"
                                type="number"
                                min="0"
                                max="100"
                                :rules="weightRules"
                                v-model="item.data.weight"
                                v-tooltip.bottom-middle="item.data.weight"
                                :disabled="item.data.isLocked"
                                label="Weight"
                              />
                            </div>
                          </div>
                        </template>
                      </Table>
                    </scroll-container>
                  </ValidationObserver>
                </div>
              </accordion>
            </div>
          </div>
        </card>
      </div>

      <div class="pl-3 flex mt-5">
        <div class="flex flex-grow">
          <Button
            :disabled="disableBtn || selectedEmployees.length === 0"
            :class="disableBtn ? `btn-disabled` : `bg-dynamicBackBtn text-white`"
            width="15rem"
            @click="handleSubmit(submit)"
          >
            <span class="mr-6 ml-6">Share with employee(s)</span>
          </Button>

          <Button
            background-color="none"
            class="ml-4"
            style="color: #321c3b"
            @click="$router.back()"
          >
            Cancel
          </Button>
        </div>
        <div class="flex justify-end mr-5">
          <Button
            class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
            width="8rem"
            @click="submit('draft')"
            :disabled="disableBtn || selectedEmployees.length === 0"
            v-if="payload.status === 'draft' && payload.title !== ''"
          >
            Save as Draft
          </Button>
        </div>
      </div>
    </ValidationObserver>

    <RightSideBar
      v-if="addEmployeesModal"
      @close="addEmployeesModal = false"
      submit="Proceed"
      @submit="employeeSubmit()"
      :button-class="`bg-dynamicBackBtn text-white`"
    >
      <template v-slot:title>
        <div class="w-full flex">
          <p class="text-darkPurple mt-3">Add Employees</p>
        </div>
      </template>
      <template v-slot:subtitle>
        <div class="flex flex-col w-full mb-3">
          <p class="my-2 text-base">
            Add employees for whom you are creating a performance agreement for.
          </p>
          <p>
            Please note that by sharing, this agreement will override all
            previously shared agreements.
          </p>
        </div>
      </template>

      <search-input class="my-4" @input="startSearch" placeholder="Search" />

      <div class="border border-dashed my-4 w-full" />

      <div style="height: 100%" v-if="loadingBar">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else>
        <div
          v-for="(item, index) in employees"
          :key="index"
          class="flex flex-col py-4 userContainer border-b border-dashed"
        >
          <div class="flex items-center">
            <div>
              <img
                class="mr-3"
                :src="item.photo"
                v-if="item.photo"
                alt="user photo"
                style="height: 35px; width: 35px; border-radius: 5px"
              />
              <div
                style="height: 35px; width: 35px; border-radius: 5px"
                class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                v-else
              >
                {{ $getInitials(`${item.fname} ${item.lname}`) }}
              </div>
            </div>
            <div class="flex flex-col flex-grow">
              <p>
                {{ `${item.fname} ${item.lname}` }}
              </p>
              <div
                class="text-xs flex font-semibold w- text-romanSilver uppercase"
              >
                <p class="opacity-50 mr-2" style="font-size: 10px">
                  {{ `${item.userDesignation ? item.userDesignation : "---"}` }}
                </p>
                <p class="opacity-50 ml-1 mr-2" style="font-size: 10px">
                  <span
                    class="dot dot-circle secondary"
                    style="width: 6px; height: 6px"
                  />
                </p>
                <p class="opacity-50 mr-2" style="font-size: 10px">
                  {{ `${item.function ? item.function : "---"}` }}
                </p>
                <p
                  class="ml-2 opacity-50 text-romanSilver"
                  v-if="item.sharedAgreement === null"
                  style="font-size: 10px"
                >
                  Not shared yet
                </p>
                <p
                  class="ml-2 opacity-50 text-carrotOrange"
                  v-else-if="item.sharedAgreement === 'Submitted And Pending'"
                  style="font-size: 10px"
                >
                  {{ item.sharedAgreement }}
                </p>
                <p
                  class="ml-2 opacity-50 text-mediumSeaGreen"
                  v-else-if="item.sharedAgreement === 'Submitted And Approved'"
                  style="font-size: 10px"
                >
                  {{ item.sharedAgreement }}
                </p>
                <p
                  class="ml-2 opacity-50 text-carrotOrange cursor-pointer"
                  v-else-if="item.sharedAgreement === 'Shared with You'"
                  style="font-size: 10px"
                  @click="viewSharedAgreement(item.agreementId, item.id)"
                >
                  {{ item.sharedAgreement }}
                </p>
              </div>
              <p
                class="opacity-50 uppercase text-carrotOrange"
                v-if="item.text !== ''"
                style="font-size: 10px"
              >
                {{ item.text }}
              </p>
            </div>
            <checkbox
              checkbox-size="height:16px; width:16px; margin:4px 7px 8px 8px;"
              label-style="color:#333333; "
              :class="item.disabled ? `opacity-30 mr-6` : 'mr-6'"
              :value="item.selected"
              @change="checkValue($event, item)"
              v-model="item.selected"
              :disabled="item.disabled"
            />
          </div>
        </div>
      </div>
    </RightSideBar>

    <RightSideBar
      v-if="viewEmployeesModal"
      close-button
      @close="viewEmployeesModal = false"
      :button-class="`bg-dynamicBackBtn text-white`"
    >
      <template v-slot:title>
        <div class="w-full flex">
          <p class="text-darkPurple mt-3">Employee Details</p>
        </div>
      </template>

      <div style="height: 100%" class="mb-4 mt-10" v-if="loadingEmp">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div class="flex items-center mb-3" v-else>
        <div>
          <img
            class="mr-3"
            :src="viewEmployee.photo"
            v-if="viewEmployee.photo"
            alt="user photo"
            style="height: 80px; width: 80px; border-radius: 5px"
          />
          <div
            style="height: 80px; width: 80px; border-radius: 5px"
            class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-7"
            v-else
          >
            {{ $getInitials(`${viewEmployee.fname} ${viewEmployee.lname}`) }}
          </div>
        </div>
        <div class="flex flex-col">
          <span class="text-lg font-bold">
            {{ `${viewEmployee.fname} ${viewEmployee.lname}` }}
          </span>
          <span class="font-semibold text-sm">
            {{
              viewEmployee.employeeId === null
                ? `---- `
                : viewEmployee.employeeId
            }}
          </span>
          <span class="text-romanSilver uppercase" style="font-size: 10px">
            Since {{ formatEmployeeDate(viewEmployee.employmentDate) }}
          </span>
        </div>
      </div>

      <div v-if="!loadingEmp">
        <div class="border border-dashed mb-4" />

        <div class="w-full my-4">
          <span class="text-base font-semibold"> Work Details </span>
        </div>
        <div class="flex flex-col w-full">
          <div class="w-full flex">
            <card class="flex p-3 mb-4 w-1/2 mr-3 flex-grow">
              <div class="flex flex-col">
                <div class="flex">
                  <icon icon-name="icon-suitcase" size="xs" />
                  <span class="ml-1 text-chartPurple text-xs">
                    Designation
                  </span>
                </div>
                <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                  {{ viewEmployee.userDesignation }}
                </div>
              </div>
            </card>
            <card class="flex p-3 mb-4 w-1/2 flex-grow">
              <div class="flex flex-col">
                <div class="flex">
                  <icon icon-name="icon-department" size="xs" />
                  <span class="ml-1 text-carrotOrange text-xs"> Function </span>
                </div>
                <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                  {{ viewEmployee.employeeFunction }}
                </div>
              </div>
            </card>
          </div>
          <div class="w-full flex">
            <card class="flex p-3 mb-4 w-1/2 mr-3 flex-grow">
              <div class="flex flex-col">
                <div class="flex">
                  <icon icon-name="levels" color="#FFFFFF" size="xs" />
                  <span class="ml-1 text-desire text-xs"> Level </span>
                </div>
                <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                  {{ viewEmployee.level }}
                </div>
              </div>
            </card>
            <card class="flex p-3 w-1/2 mb-4 flex-grow">
              <div class="flex flex-col">
                <div class="flex">
                  <icon icon-name="line-manager" color="#FFFFFF" size="xs" />
                  <span class="ml-1 text-blueCrayola text-xs">
                    Line Manager
                  </span>
                </div>
                <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                  {{ viewEmployee.reportingTo }}
                </div>
              </div>
            </card>
          </div>
        </div>

        <div class="w-full flex">
          <card class="flex p-3 mb-4 w-1/2 mr-3 flex-grow">
            <div class="flex flex-col">
              <div class="flex">
                <icon icon-name="icon-manager" color="#878E99" size="xs" />
                <span class="ml-1 text-mediumSeaGreen text-xs">
                  Supervisory Function
                </span>
              </div>
              <div
                class="text-xs ml-5 mt-1 font-semibold uppercase sd"
                v-if="viewEmployee.supervisory"
              >
                {{ viewEmployee.userDesignation }}
              </div>
              <div class="text-xs ml-5 mt-1 font-semibold uppercase" v-else>
                ----
              </div>
            </div>
          </card>
          <card class="flex p-3 w-1/2 mb-4 flex-grow">
            <div class="flex flex-col">
              <div class="flex">
                <icon icon-name="icon-display-grid" color="#878E99" size="xs" />
                <span class="ml-1 text-blueCrayola text-xs">
                  Employment Type
                </span>
              </div>
              <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                {{ viewEmployee.employmentType }}
              </div>
            </div>
          </card>
        </div>

        <div class="border border-dashed w-full" />

        <div class="w-full my-4">
          <span class="text-base font-semibold"> Others </span>
        </div>
        <div class="flex flex-col w-full">
          <div class="w-full flex">
            <card class="flex p-3 mb-4 mr-3 flex-grow">
              <div class="flex flex-col">
                <div class="flex">
                  <icon icon-name="message" size="xms" />
                  <span class="ml-2 text-blueCrayola text-xs"> Email </span>
                </div>
                <div class="text-xs ml-5 mt-1 font-semibold">
                  {{ viewEmployee.email }}
                </div>
              </div>
            </card>
            <card class="flex p-3 mb-4">
              <div class="flex flex-col">
                <div class="flex">
                  <icon icon-name="icon-phone" size="xs" />
                  <span class="ml-1 text-carrotOrange text-xs"> Phone </span>
                </div>
                <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                  {{ viewEmployee.phoneNo }}
                </div>
              </div>
            </card>
          </div>

          <div class="w-full flex">
            <card class="flex p-3 mb-4 mr-3 flex-grow">
              <div class="flex flex-col">
                <div class="flex">
                  <icon icon-name="map-pin" size="xms" />
                  <span class="ml-2 text-dome text-xs"> Office Location </span>
                </div>
                <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                  {{ viewEmployee.office }}
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import ScrollContainer from "@/components/ScrollContainer";
import RightSideBar from "@/components/RightSideBar";
import Accordion from "@/components/Accordion";
import paramsMixin from '@/utilities/paramsMixin';
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    ValidationObserver,
    BackButton,
    Breadcrumb,
    Icon,
    CText,
    Card,
    Button,
    RightSideBar,
    SearchInput,
    Checkbox,
    Badge,
    CSelect,
    Accordion,
    Table,
    ScrollContainer,
    Datepicker,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Agreement",
          href: "Performance Agreement",
          id: "Agreement",
        },
        {
          disabled: false,
          text: "Shared With Me",
          href: "Shared With Me",
          id: "Shared With Me",
        },
        {
          disabled: false,
          text: "Draft Agreement",
          id: "Draft Agreement",
        },
      ],
      illustration: "illustration",
      category: [
        {
          name: "Allow employees decide what to do",
          radioName: "descision",
          value: "allow",
        },
        {
          name: "Override previous shared agreements",
          radioName: "descision",
          value: "override",
        },
      ],
      allocatedWeight: 0,
      templateKPATypes: [],
      weightRules: [
        "required",
        {
          name: "weight",
          rule: (w) => this.validateWeight(w),
        },
      ],
      KPAHeader: [
        { title: "•", value: "state" },
        { title: "KPA", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "Criteria", value: "kpis" },
        { title: "Target Date", value: "target_date" },
        { title: "Weight (%)", value: "weight" },
      ],
      loadingBar: true,
      agreementAction: "",
      loadingEmp: true,
      moreIcon: "more_icon",
      doccycle: "doc-cycle",
      calendarIcon: "calendar",
      reviewIcon: "reviewicon",
      starreviewIcon: "star-review",
      iconInfo: "icon-info",
      iconPlus: "icon-plus",
      iconCopy: "icon-copy",
      iconEdit: "icon-edit",
      iconChevron: "chevronRight",
      disableBtn: true,
      watchOthers: false,
      startSearch: "",
      addEmployeesModal: false,
      periodError: false,
      employees: [],
      selectedEmployees: [],
      viewEmployee: [],
      viewEmployeesModal: false,
      previousGoalModal: false,
      Ratings: [],
      kpaWeights: [],
      kpaName: [],
      totalWeight: "",
      templateIds: [],
      ratingsIds: [],
      templateOptions: [],
      prevAgreementKPA: [],
      selectedTemplate: "",
      cycleStartPeriod: "",
      cycleEndPeriod: "",
      cycleDescription: "",
      cycleTitle: "",
      appraisal_cycles: [],
      functions: [],
      kpaData: [],
      draftAgreementKPA: [],
      validateKpa: "",
      setNewAgreement: false,
      agreementKPA: [],
      managerLock: false,
      applicableEmployees: [],
      setSelectedEmployees: true,
      checkedEmployees: [],
      payload: {
        orgId: "",
        goalId: "",
        templateId: "",
        title: "",
        year: "",
        status: "draft",
        share_for_review: false,
        created_for: [],
        created_by: "",
        kpa_types: [],
      },
    };
  },

  watch: {
    selectedTemplate(value) {
      if (!this.setSelectedEmployees) {
        this.selectedEmployees = [];
      }

      this.employees.map((employee) => {
        // eslint-disable-next-line no-param-reassign
        employee.disabled = false;
        // eslint-disable-next-line no-param-reassign
        employee.text = "";

        if (!this.setNewAgreement && !this.setSelectedEmployees) {
          // eslint-disable-next-line no-param-reassign
          employee.selected = false;
        }

        return {};
      });

      this.setApplicableEmployees(value);

      this.$_getOneTemplate(value).then((response) => {
        this.$_getKPAWeights(value).then((res) => {
          const kpaData = res.data.data;

          this.kpaWeights = kpaData;

          let sum = 0;

          this.kpaWeights.forEach((kpa) => {
            sum += kpa.totalKpiWeight;
          });

          this.totalWeight = sum;
        });

        this.disableBtn = false;

        this.payload.templateId = value;

        this.cycleDescription =
          response.data.PerformanceTemplate.agreement_description;

        this.cycleTitle = response.data.PerformanceTemplate.agreement_title;

        this.payload.title = this.cycleTitle;

        this.templateKPATypes = response.data.PerformanceTemplate.kpa_types;

        if (this.agreementKPA.length === 0) {
          if (this.prevAgreementKPA.length === 0) {
            this.templateKPATypes.forEach((kpaType) => {
              // eslint-disable-next-line no-param-reassign
              kpaType.kpas = [];
              // eslint-disable-next-line no-param-reassign
              kpaType.org_template_kpas = {};

              this.draftAgreementKPA.filter((employeeKpi) => {
                if (employeeKpi.kpaTypeId === kpaType.id) {
                  kpaType.kpas.push({
                    state: true,
                    isLocked: employeeKpi.isLocked,
                    name: employeeKpi.name,
                    objective: employeeKpi.objective,
                    kpaId: employeeKpi.id,
                    kpis: this.getCriteriaData(employeeKpi.kpis_employee),
                    target_date: this.resetDate(employeeKpi.targetDate),
                    weight: employeeKpi.weight,
                  });
                }
                return {};
              });
            });
          } else {
            this.setPrevAgreementData(this.prevAgreementKPA);
          }
        } else {
          this.setAgreementData(this.agreementKPA);
        }
      });
    },
  },

  methods: {
    lockKPA(isLocked, data) {
      if (this.managerLock) {
        // eslint-disable-next-line no-param-reassign
        data.isLocked = !isLocked;
      } else {
        this.$toasted.error(
          "You do not have the privilege to lock or unlock an agreement",
          { duration: 5000 }
        );
      }
    },

    deleteKPA(index, kpaTypes, id) {
      let sum = 0;
      if(kpaTypes.kpas.length > 1) {
        kpaTypes.kpas.splice(index, 1);

        this.$_deleteAgreementKPI(id).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });

          this.templateKPATypes.forEach((kpaType) => {
            kpaType.kpas.map((kpi) => {
              sum += kpi.weight;
              return {};
            });
            return {};
          });

          this.allocatedWeight = sum;
        });
      } else {
        this.$toasted.error('Cannot delete ALL KPIs of a KPA Type, you can edit KPI values instead', {duration: 5000});
      }
    },

    setAgreementData(agreementKPA) {
      this.templateKPATypes.forEach((kpaType) => {
        // eslint-disable-next-line no-param-reassign
        kpaType.kpas = [];
        // eslint-disable-next-line no-param-reassign
        kpaType.org_template_kpas = {};

        agreementKPA.filter((employeeKpi) => {
          if (employeeKpi.kpaTypeId === kpaType.id) {
            kpaType.kpas.push({
              state: true,
              isLocked: employeeKpi.isLocked,
              name: employeeKpi.name,
              objective: employeeKpi.objective,
              kpaId: employeeKpi.id,
              kpis: this.getCriteriaData(employeeKpi.kpis_employee),
              target_date: this.resetDate(employeeKpi.targetDate),
              weight: employeeKpi.weight,
            });
          }
          return {};
        });
      });

      setTimeout(() => {
        window.localStorage.removeItem("adoptAgreement");
      }, 2000);
    },

    setPrevAgreementData(prevAgreementKPA) {
      this.templateKPATypes = prevAgreementKPA;
      setTimeout(() => {
        window.localStorage.removeItem("createAgreement");
        this.setNewAgreement = false;
      }, 2000);
    },

    getKPAWeight(kpaTypes, kpaTypesId) {
      let sum = 0;

      if (kpaTypes.id === kpaTypesId) {
        kpaTypes.kpas.forEach((kpi) => {
          sum += Number(kpi.weight);
        });

        this.kpaWeights.filter((kpa) => {
          if (kpa.id === kpaTypesId) {
            // eslint-disable-next-line no-param-reassign
            kpa.weight = sum;
          }
          return {};
        });
      }

      return sum || 0;
    },

    getTotalWeight(kpaTypes, kpaTypesId) {
      let sum = 0;
      this.kpaWeights.filter((kpa) => {
        if (kpa.id === kpaTypesId) {
          sum = kpa.totalKpiWeight;
        }
        return {};
      });

      return sum;
    },

    createNewKPA(kpa) {
      const currentIndex = (kpa.kpas.length - 1);
      const arrayObj = kpa.kpas[currentIndex];
      let isEmpty = true;

      if (
        arrayObj.name !== "" &&
        arrayObj.objective !== "" &&
        arrayObj.weight !== ""
      ) {
        // iterate over criteria array
        arrayObj.kpis.forEach((v) => {
          if (Object.keys(v).every((k) => v[k] !== "")) {
            isEmpty = false;
          }
        });
      }

      this.addKPATable(kpa.id, isEmpty);
    },

    addKPATable(kpaId, valid) {
      if (!valid) {
        if (this.allocatedWeight < 100) {
          this.templateKPATypes.forEach((kpaType) => {
            if (kpaType.id === kpaId) {
              kpaType.kpas.push({
                state: true,
                isLocked: false,
                name: null,
                objective: null,
                kpis: this.getCriteriaData(),
                target_date: "",
                weight: "",
              });
            }
            return {};
          });
        } else {
          this.$toasted.error(
            "Total allocated weight for all KPA's cannot be greater than 100%",
            { duration: 5000 }
          );
        }
      }
    },

    validateWeight(weight) {
      let sum = 0;
      if (weight < 0) {
        return "Value must be greater than or equal to 0";
      }

      this.templateKPATypes.forEach((kpaType) => {
        kpaType.kpas.map((kpi) => {
          sum += Number(kpi.weight);
          return {};
        });
        return {};
      });

      if (sum > 100) {
        return "Total allocated weight for all KPA's cannot be greater than 100%";
      }

      this.allocatedWeight = sum;

      return true;
    },

    periodSelect() {
      this.periodError = false;
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

      this.Ratings = sortedRatings;

      if (kpis) {
        this.Ratings.map((rating, index) => {
          criteriaData.push({
            id: kpis[index].id,
            performanceRatingId: rating.id,
            criteria: kpis[index].criteria,
          });
          return {};
        });
      } else {
        this.Ratings.map((rating) => {
          criteriaData.push({
            performanceRatingId: rating.id,
            criteria: "",
          });
          return {};
        });
      }

      return criteriaData;
    },

    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    formatEmployeeDate(value) {
      let initialDate = "";

      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");

        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }

      return "";
    },

    submit(status) {
      this.kpaData = [];

      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas.map(({ state, kpaId, ...rest }) => ({
          ...rest,
        }));

        newKPAs.map((kpi) => {
          const kpiData = kpi;
          kpiData.target_date = this.formatDate(kpiData.target_date, "arrange");

          return {};
        });

        const validation = newKPAs.every(
          (item) => item.name || item.objective || item.weight
        );

        if (validation) {
          this.kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: newKPAs,
          });
        } else {
          this.kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: [],
          });
        }

        return {};
      });

      this.payload.kpa_types = this.kpaData;
      this.payload.orgId = this.$orgId;

      this.selectedEmployees.map((selected) => {
        this.payload.created_for.push(selected.id);
        return {};
      });

      this.payload.created_by = this.$AuthUser.id;

      if (!status) {
        this.payload.status = "active";
        this.payload.share_for_review = true;
      }

      if (this.payload.year !== "") {
        this.payload.year = new Date(this.payload.year).getFullYear();

        this.payload.description = this.payload.title;
        this.disableBtn = true;

        this.$_createManagerAgreement(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.$router.push({
              name: "EssAgrreements",
              query: { activeTab: "My Direct Reports" },
            });
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message, {
              duration: 5000,
            });
          });
      } else if (this.payload.year === "") {
        this.periodError = true;
        this.payload.status = "draft";
      }
    },

    viewSharedAgreement(agreementId, userId) {
      this.selectedEmployees = [];

      this.employees.filter((employee) => {
        if (employee.selected) {
          this.selectedEmployees.push(employee.id);
        }
        return {};
      });

      const agreementStorage = {
        templateId: this.payload.templateId,
        year: this.payload.year,
        addModal: true,
        employeesList: this.employees,
        agreementKPA: this.templateKPATypes,
      };

      window.localStorage.setItem(
        "createAgreement",
        JSON.stringify(agreementStorage)
      );

      this.$router.push({
        name: "AdoptSharedAgreement",
        params: { id: agreementId, user: userId },
      });
    },

    setApplicableEmployees(templateId) {
      this.templateIds.filter((templateObj) => {
        if (templateObj.templateId === templateId) {
          this.employees.map((employee) => {
            if (templateObj.employees.includes(employee.id)) {
              // eslint-disable-next-line no-param-reassign
              employee.disabled = false;
              // eslint-disable-next-line no-param-reassign
              employee.text = "";
            } else {
              // eslint-disable-next-line no-param-reassign
              employee.disabled = true;
              // eslint-disable-next-line no-param-reassign
              employee.text = "Selected Template not applicable to employee";
              // eslint-disable-next-line no-param-reassign
              employee.sharedAgreement = "";
            }
            return {};
          });
        }
        return {};
      });
    },

    viewEmployeeDetails(employeeId) {
      const viewEmployee = [];
      const payload = { id: employeeId };
      this.viewEmployeesModal = true;
      const employeeObj = [];
      this.loadingEmp = true;

      this.$_getOneEmployeeAll(payload).then((response) => {
        employeeObj.push({
          id: response.data.id,
          fname: response.data.fname,
          lname: response.data.lname,
          photo: response.data.photo,
          employeeId: response.data.employeeId,
          userDesignation: response.data.userDesignation.name,
          employmentDate: response.data.employmentDate,
          level: response.data.userLevel.name,
          reportingTo: `${response.data.userReportingTo.fname} ${response.data.userReportingTo.lname}`,
          employmentType: response.data.employmentType,
          supervisory: response.data.userDesignation.isSupervisory,
          email: response.data.email,
          phoneNo: response.data.phoneNo,
          office: response.data.employeeOffice.name,
        });

        this.functions.filter((f) => {
          if (f.id === response.data.functionId) {
            employeeObj.push({
              employeeFunction: f.name,
            });
          }
          return {};
        });

        viewEmployee.push(
          employeeObj.reduce((r, i) => Object.assign(r, i), {})
        );

        this.viewEmployee = Object.assign({}, ...viewEmployee);

        this.loadingEmp = false;
      });
    },

    addEmployees() {
      if (this.selectedTemplate === "") {
        this.$toasted.error("Select a template to proceed", { duration: 5000 });
      } else {
        this.addEmployeesModal = true;
      }
    },

    employeeSubmit() {
      this.selectedEmployees = [];
      this.employees.filter((employee) => {
        if (employee.selected) {
          this.selectedEmployees.push(employee);
        }
        return {};
      });

      this.addEmployeesModal = false;
    },

    removeEmployee(index, employeeData) {
      this.selectedEmployees.splice(index, 1);

      this.employees.filter((employee) => {
        if (employee.id === employeeData.id) {
          // eslint-disable-next-line no-param-reassign
          employee.selected = false;
        }
        return {};
      });
    },

    setEmployeesData(employeesList) {
      employeesList.map((employee) => {
        this.employees.push({
          id: employee.id,
          fname: employee.fname,
          lname: employee.lname,
          userDesignation: employee.userDesignation,
          function: employee.function,
          photo: employee.photo,
          selected: employee.selected,
          sharedAgreement: employee.sharedAgreement,
          sharedId: employee.sharedId,
          agreementId: employee.agreementId,
          disabled: false,
          text: "",
        });
        return {};
      });

      this.addEmployeesModal = true;
    },

    getReportingEmployees() {
      this.loadingBar = true;
      this.$_getManagerDirectReport(this.$AuthUser.id).then((response) => {
        this.loadingBar = false;
        response.data.directReport.map((employee) => {
          this.employees.push({
            id: employee.userId,
            fname: employee.fname,
            lname: employee.lname,
            userDesignation: employee.designation,
            function: employee.function,
            photo: employee.photo,
            selected: false,
            sharedAgreement: "",
            disabled: false,
            text: "",
          });

          this.applicableEmployees.push(employee.userId);
          return {};
        });

        if (this.setSelectedEmployees) {
          this.employees.filter((employee) => {
            if (this.checkedEmployees.includes(employee.id)) {
              // eslint-disable-next-line no-param-reassign
              employee.selected = true;
              this.checkValue(true, employee);
            }
            return {};
          });
          this.employeeSubmit();
        }
      });
    },

    checkValue(value, item) {
      if (value) {
        this.$_checkEmployeeSharedAgreement(item.id).then((response) => {
          // eslint-disable-next-line no-param-reassign
          item.sharedAgreement = response.data.status.submitted;

          if (item.sharedAgreement === `Shared with You`) {
            // eslint-disable-next-line no-param-reassign
            item.sharedId = response.data.sharedAgreement.id;
            // eslint-disable-next-line no-param-reassign
            item.agreementId =
              response.data.sharedAgreement.employeePerformanceAgreementId;
          }
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        item.sharedAgreement = "";
      }
    },

    getTemplates() {
      let templatesArr = [];

      this.$_getPerfomanceTemplate('').then((response) => {
        templatesArr = response.data.PerformanceTemplate;

        templatesArr.filter((template) => {
          if (template.status !== "draft") {
            const found = this.templateIds.some(
              (el) => el.templateId === template.id
            );
            if (found) {
              this.templateIds.filter((templateObj) => {
                if (
                  templateObj.employees.includes(this.$AuthUser.id) &&
                  templateObj.templateId === template.id
                ) {
                  this.templateOptions.push({
                    id: template.id,
                    name: template.title,
                  });
                }
                return {};
              });
            }
          }
          return {};
        });

        this.loading = false;

        if (this.agreementKPA.length === 0) {
          this.getAgreement();
        }
      });
    },

    getGoals() {
      this.$_getOrganizationGoal('').then((response) => {
        const goalData = response.data.goals;
        const ids = [];
        const idNames = [];

        goalData.forEach((goals) => {

          if (goals.goal.year === Number(this.$route.params.year)) {
            this.payload.goalId = goals.goal.id;
          }

          if (idNames.includes(goals.goal.goal_performance_templates.id)) {
            ids.filter((idData) => {
              if (idData === goals.goal.goal_performance_templates.id) {
                idData.employees.push(goals.appliedEmployees);
              }
              return {};
            });
          } else {
            ids.push({
              templateId: goals.goal.goal_performance_templates.id,
              employees: goals.appliedEmployees,
            });
          }

          idNames.push(goals.goal.goal_performance_templates.id);
        });

        this.templateIds = ids;
        this.getTemplates();
      });
    },

    getAppraisalCycles() {
      this.$_getAppraisalCycle(this.agreementQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        appraisal.map((appraisalCycles) => {
          const startDate = this.setDates(`${
            appraisalCycles.cycle_start_date
          }`);

          const endDate = this.setDates(`${
            appraisalCycles.cycle_end_date
          }`);

          this.cycleStartPeriod = startDate.toISOString();

          this.cycleEndPeriod = endDate.toISOString();

          appraisalCycles.cycles.forEach((cycle) => {
            const startCycleDate = this.setDates(`${
              cycle.appraisal_starts
            }`);

            const endCycleDate = this.setDates(`${
              cycle.appraisal_ends
            }`);

            this.appraisal_cycles.push({
              name: cycle.name,
              appraisal_starts: startCycleDate.toISOString(),
              appraisal_ends: endCycleDate.toISOString(),
            });
          });

          return {};
        });
      });
    },

    getSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        const settings = response.data.PerformanceSettings;

        this.managerLock = settings[0].managerCanLockAgreement;
      });
    },

    getFunctions() {
      this.functions = [];
      this.$_getFunctionKpis("").then((response) => {
        response.data.functions.forEach((f) => {
          this.functions.push({
            id: f.id,
            name: f.name,
          });
        });
      });
    },

    getAgreement() {
      this.$_getOneAgreement(this.$route.params.agreement).then((response) => {
        const agreementData = response.data.agreement;

        this.draftAgreementKPA = agreementData.employee_agreement_kpa;
        this.selectedTemplate = agreementData.performance_template.id;

        this.payload.year = new Date(Number(agreementData.year), 0);
      });
    },

    getDirectReports() {
      this.$_getDirectReportAgreements(this.$AuthUser.id).then((response) => {
        const agreements = response.data;

        if (agreements.draftAgreement.length > 0) {
          agreements.draftAgreement.map((draft) => {
            this.checkedEmployees.push(draft.owner.userId);
            return {};
          });
        }

        this.getReportingEmployees();
      });
    },
  },

  mounted() {
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;
    });
    this.getFunctions();
    this.getAppraisalCycles();
    this.getSettings();
    this.getGoals();

    const adoptedAgreement = JSON.parse(
      window.localStorage.getItem("adoptAgreement")
    );

    if (adoptedAgreement !== null) {
      this.agreementKPA = adoptedAgreement.employee_agreement_kpa;
      this.selectedTemplate = adoptedAgreement.templateId;
      this.payload.year = adoptedAgreement.year;

      setTimeout(() => {
        window.localStorage.removeItem("createAgreement");
        this.setNewAgreement = false;
      }, 2000);
    }

    const newAgreement = JSON.parse(
      window.localStorage.getItem("createAgreement")
    );

    if (newAgreement !== null) {
      this.setNewAgreement = true;
      this.prevAgreementKPA = newAgreement.agreementKPA;
      this.selectedTemplate = newAgreement.templateId;
      this.payload.year = newAgreement.year;
      this.setSelectedEmployees = false;
      this.loadingBar = false;

      this.setEmployeesData(newAgreement.employeesList);
    }

    if (newAgreement === null) {
      this.getDirectReports();
    }
  },
};
</script>

<style>
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}

.cyc-text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
}

.inner-text {
  font-size: 12px;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
  color: #878e99;
  text-transform: uppercase;
}

.label label {
  display: none;
}

.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 12px;
  height: 12px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 0.5px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}

.icon-Size {
  width: 200px;
  height: 200px;
}
.illu-text {
  text-align: center;
  font-weight: 400;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.015em;
}
</style>